const language = {
    BASED_IN: 'Duesseldorf, Germany ',
    CONTACT_TITlE: 'Get in touch',
    EMAIL: 'E-Mail',
    GITHUB: 'Github',
    HEADER_SUBTITLE: 'a work in progress',
    HEADER_TITLE: 'siffert[.io]',
    JOB: 'Software Developer',
    LINK_EMAIL: 'mailto:siffert.dev+io@gmail.com',
    LINK_GITHUB: 'https://github.com/FabianSiffert1?tab=repositories',
    LINK_LINKEDIN:'https://www.linkedin.com/in/fabian-siffert-5b2481289/',
    LINKEDIN:'LinkedIn',
    LOGO: 'LOGO_TEXT',
    LOREM: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ab hoc autem quaedam non melius quam veteres, quaedam omnino relicta. Quae contraria sunt his, malane? Quid enim ab antiquis ex eo genere, quod ad disserendum valet, praetermissum est? Duo Reges: constructio interrete. Bonum appello quicquid secundurn naturam est, quod contra malum, nec ego solus, sed tu etiam, Chrysippe, in foro, domi; Aliis esse maiora, illud dubium, ad id, quod summum bonum dicitis, ecquaenam possit fieri accessio. Vos autem cum perspicuis dubia debeatis illustrare, dubiis perspicua conamini tollere. Sin est etiam corpus, ista explanatio naturae nempe hoc effecerit, ut ea, quae ante explanationem tenebamus, relinquamus. Cur ad reliquos Pythagoreos, Echecratem, Timaeum, Arionem, Locros, ut, cum Socratem expressisset, adiungeret Pythagoreorum disciplinam eaque, quae Socrates repudiabat, addisceret? Tenesne igitur, inquam, Hieronymus Rhodius quid dicat esse summum bonum, quo putet omnia referri oportere? Cum vero paulum processerunt, lusionibus vel laboriosis delectantur, ut ne verberibus quidem deterreri possint, eaque cupiditas agendi aliquid adolescit una cum aetatibus. Quae diligentissime contra Aristonem dicuntur a Chryippo.',
    NAME: 'Fabian Siffert',
    OTHER_SKILLS_TITLE: 'Other Qualifications',
    ENGLISH_STUDIES_TITLE: 'English and American Literature & Philosophy',
    ENGLISH_STUDIES_DESCRIPTION: 'I hold a Bachelor of Arts degree from Heinrich Heine University in Duesseldorf, with a major in English and American Literature and a minor in Philosophy. \n\n To improve my English, I spent nearly a year living and studying in Port Elizabeth/Gqeberha, South Africa. During this time, I volunteered at Walmer Secondary School and the Masifunde Changemaker Academy, actively trying to contribute to the local community and gaining valuable experiences.',
    PAGE_NOT_FOUND_SUBTITLE: 'page not found (please leave)',
    PAGE_NOT_FOUND_TITLE: '404',
    PROJECTS_TITLE: 'Projects',
    PROJECT_INV_DESCRIPTION: 'A WIP mobile first website that was supposed to replace my portfolio, but changed into a Pokemon Trading Card Game Website to help me sell my childhood cards for a reasonable price.',
    PROJECT_SATELLITE_DESCRIPTION: 'I contribute to the development of a professional mobile telephone system app with a significant monthly user base in my current workplace.',
    PROJECT_SIFFERTIO: 'siffert.io',
    PROJECT_SIFFERTIO_DESCRIPTION: 'A personal portfolio with a focus on simplistic design. A pet project written over the course of my apprenticeship. Be wary: Code quality may vary. \n\nPlease note that responsiveness is not a priority, and the page is best viewed in a web browser rather than a mobile phone.',
    PROJECT_SIFFERTIOS: 'siffert.ios',
    QUALIFICATIONS_TITLE: 'Various',
    QUALIFICATIONS_DESCRIPTION: 'I started out as an apprentice Software Engineer at sipgate in Duesseldorf, and I wrapped up my apprenticeship in January 2024. Now I\'m working full-time at sipgate. \n\n sipgate is widely recognized for its innovative work culture and leadership in Scrum and agile methodologies. Additionally, I had the privilege of completing a product owner training and obtaining certification as a Scrum Product Owner until December 2024.',
    ROUTES_SKILLS: 'Skills',
    ROUTE_ABOUT: 'Home',
    ROUTE_PROJECTS: 'Projects',
    SECTION_ABOUT: 'About',
    SKILLS_ANDROID: 'Android',
    SKILLS_IOS: 'iOS',
    SKILLS_REACT: 'React',
    SKILLS_TITLE: 'Programming',
    THEME_BUTTON: 'Theme',
    THEME_COLORFUL: 'Colorful',
    THEME_DARK: 'Dark',
    THEME_LIGHT: 'Light',
    WELCOME_SUBTITLE: 'a work in progress',
    WELCOME_TITLE: 'siffert.io',
};
export default language;
